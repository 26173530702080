body {
  background-color: #f2f4f7;
  color: #344054;
  margin: 0;

  font-family: "Inter", sans-serif !important;
  font-optical-sizing: auto;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.36px;
  text-align: left;
}

.MuiFormLabel-asterisk {
  color: #f04438;
}

.centered-input {
  text-align: center !important;
}

.MuiDataGrid-filler {
  display: none !important;
}

.MuiDataGrid-overlayWrapperInner {
  display: none;
}

.MuiDataGrid-root {
  border: none !important;
}

.font-500 {
  font-weight: 500;
}
